import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

function DoorIcon(props: { color: String }) {
    return (
        <SvgIcon sx={{ color: `${props.color}` }} fontSize="large">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
            >
                <path
                    d="M5 22V2h14v20H5z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
                <path
                    d="M16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 5h8v14H8V5z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
            </svg>
        </SvgIcon>
    );
}

export default DoorIcon;