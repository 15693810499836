import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

function WindowIcon(props: { color: String }) {
    return (
        <SvgIcon sx={{ color: `${props.color}`, scale: "0.9" }} fontSize="large" >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
            >
                <path
                    d="M2 22h20V12C22 6.477 17.523 2 12 2S2 6.477 2 12v10z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
                <path
                    d="M2 12h20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 22V2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
}

export default WindowIcon;