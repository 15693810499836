import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ButtonStates } from "./appState.model";
import {
	Label,
	Room,
	Wall,
} from "../../../features/outputEditor/components/Floorplan";

const initialState: ButtonStates = {
	drawLine: false,
	drawWindow: false,
	drawDoor: false,
	windowAssets: [],
	doorAssets: [],
	rooms: undefined,
	walls: undefined,
	labels: undefined,
	addAsset: {
		assetType: null,
	},
	cleanUpDone: false,
	doRoomCleanUp: true,
	undoWalls: [],
	undoRooms: [],
	redoWalls: [],
	redoRooms: [],
	undoPlot: [],
	redoPlot: [],
	showPlot: false,
	plot_boundary: undefined,
	unit: "feet",
	selectedWall: undefined,
	selectedWallOption: null,
};

export const outputEditorSlice = createSlice({
	name: "ouputEditorButtonState",
	initialState,
	reducers: {
		reset: () => initialState,
		setDrawLine: (state) => {
			state.drawLine = true;
		},
		clearDrawLine: (state) => {
			state.drawLine = false;
		},
		setDrawWindow: (state) => {
			state.drawWindow = true;
		},
		clearDrawWindow: (state) => {
			state.drawWindow = false;
		},
		setDrawDoor: (state) => {
			state.drawDoor = true;
		},
		clearDrawDoor: (state) => {
			state.drawDoor = false;
		},
		setWindowAssets: (state, action: PayloadAction<Array<{ id: string, x: number, y: number, isVertical: boolean }>>) => {
			state.windowAssets = action.payload;
		},
		setDoorAssets: (state, action: PayloadAction<Array<{ id: string, x: number, y: number, isVertical: boolean }>>) => {
			state.doorAssets = action.payload;
		},
		setRooms: (state, action: PayloadAction<Room[]>) => {
			state.rooms = action.payload;
			console.log(state.rooms);
		},
		addRooms: (state, action: PayloadAction<Room>) => {
			if (state.rooms === undefined) state.rooms = [];
			state?.rooms?.push(action.payload); // Directly append the new room
		},
		setWalls: (state, action: PayloadAction<Wall[]>) => {
			state.walls = action.payload;
			console.log(state.walls);
		},
		addWalls: (state, action: PayloadAction<Wall>) => {
			if (state.walls === undefined) {
				state.walls = [];
			}
			state.walls.push(action.payload);
		},
		addLabels: (state, action: PayloadAction<Label>) => {
			if (state.labels === undefined) {
				state.labels = [];
			}
			state.labels.push(action.payload); // Directly append the new wall
		},
		setAssetType: (state, action: PayloadAction<"window" | "door" | null>) => {
			state.addAsset.assetType = action.payload;
		},
		resetRooms: (state, action: PayloadAction) => {
			state.rooms = [];
		},
		resetStates: (state, action: PayloadAction) => {
			state.rooms = undefined;
			state.walls = undefined;
			state.labels = undefined;
			state.undoWalls = [];
			state.undoRooms = [];
			state.undoPlot = [];
			state.redoWalls = [];
			state.redoRooms = [];
			state.redoPlot = [];
			state.plot_boundary = undefined;
			state.showPlot = false;
			state.drawLine = false;
			state.drawWindow = false;
			state.drawDoor = false;
			state.doorAssets = [];
			state.windowAssets = [];
		},
		resetLabels: (state, action: PayloadAction) => {
			state.labels = [];
		},
		resetWalls: (state, action: PayloadAction) => {
			state.walls = [];
		},
		pushUndoStacks: (state, action: PayloadAction<{ rooms: Room[] | undefined, walls: Wall[] | undefined, plot: Wall[] | undefined }>) => {
			if (state.undoWalls === undefined) {
				state.undoWalls = [];
			}
			if (state.undoRooms === undefined) {
				state.undoRooms = [];
			}
			if (state.redoWalls === undefined) {
				state.redoWalls = [];
			}
			if (state.redoRooms === undefined) {
				state.redoRooms = [];
			}
			if (state.undoPlot === undefined) {
				state.undoPlot = [];
			}
			if (state.redoPlot === undefined) {
				state.redoPlot = [];
			}
			if (action.payload.walls && action.payload.walls.length > 0) {
				state.undoWalls = [...state.undoWalls, action.payload.walls]
			}
			if (action.payload.rooms && action.payload.rooms.length > 0) {
				state.undoRooms = [...state.undoRooms, action.payload.rooms]
			}
			if (action.payload.plot && action.payload.plot.length > 0) {
				state.undoPlot = [...state.undoPlot, action.payload.plot]
			}
		},
		popUndoStacks: (state) => {
			if (state.undoWalls.length > 0) {
				state.undoWalls.pop();
			}
			if (state.undoRooms.length > 0) {
				state.undoRooms.pop();
			}
			if (state.undoPlot.length > 0) {
				state.undoPlot.pop();
			}
		},
		pushRedoStacks: (state, action: PayloadAction<{ rooms: Room[] | undefined, walls: Wall[] | undefined, plot: Wall[] | undefined }>) => {
			if (state.redoWalls === undefined) {
				state.redoWalls = [];
			}
			if (state.redoRooms === undefined) {
				state.redoRooms = [];
			}
			if (state.redoPlot === undefined) {
				state.redoPlot = [];
			}
			if (action.payload.walls) {
				state.redoWalls = [...state.redoWalls, action.payload.walls]
			}
			if (action.payload.rooms) {
				state.redoRooms = [...state.redoRooms, action.payload.rooms]
			}
			if (action.payload.plot) {
				state.redoPlot = [...state.redoPlot, action.payload.plot]
			}
		},
		popRedoStacks: (state) => {
			if (state.redoWalls?.length > 0) {
				state.redoWalls.pop();
			}
			if (state.redoRooms?.length > 0) {
				state.redoRooms.pop();
			}
			if (state.redoPlot?.length > 0) {
				state.redoPlot.pop();
			}
		},
		clearRedoStacks: (state) => {
			state.redoWalls = [];
			state.redoRooms = [];
			state.redoPlot = [];
		},
		setCleanUpDone: (state, action: PayloadAction<boolean>) => {
			state.cleanUpDone = action.payload;
		},
		setCleanUpRequired: (state, action: PayloadAction<boolean>) => {
			state.doRoomCleanUp = action.payload;
		},
		setPlot: (state, action: PayloadAction<Wall[] | undefined>) => {
			state.plot_boundary = action.payload;
		},
		togglePlot: (state) => {
			state.showPlot = !state.showPlot;
		},
		toggleUnit: (state) => {
			state.unit = state.unit === "feet" ? "meters" : "feet";
		},
		setSelectedWall: (state, action: PayloadAction<Wall | undefined>) => {
			state.selectedWall = action.payload;
		},
		setSelectedWallOption: (state, action: PayloadAction<string | null>) => {
			state.selectedWallOption = action.payload;
		}
	},
});

export const {
	setDrawLine,
	clearDrawLine,
	setDrawWindow,
	clearDrawWindow,
	setDrawDoor,
	clearDrawDoor,
	setDoorAssets,
	setWindowAssets,
	setRooms,
	addRooms,
	setWalls,
	addWalls,
	setAssetType,
	resetRooms,
	resetLabels,
	resetWalls,
	setCleanUpDone,
	setCleanUpRequired,
	addLabels,
	resetStates,
	reset,
	pushUndoStacks,
	pushRedoStacks,
	popUndoStacks,
	popRedoStacks,
	clearRedoStacks,
	setPlot,
	togglePlot,
	toggleUnit,
	setSelectedWall,
	setSelectedWallOption,
} = outputEditorSlice.actions;
export default outputEditorSlice.reducer;
