import React, { useRef, useState, useEffect } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import TopNav from "./TopNav";
import { useFloorPlan } from "../hooks/useFloorPlan";
import { useRoomCleanUp } from "../hooks/useRoomCleanup";
import { useDispatch, useSelector } from "react-redux";
import {
  clearRedoStacks,
  pushUndoStacks,
  setCleanUpDone,
  setCleanUpRequired,
  setRooms,
  setWalls,
  setWindowAssets,
  setDoorAssets,
} from "../../../redux/features/appState";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../../redux/store";
import { Room, Wall } from "./Floorplan";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import svg2pdf from "svg2pdf.js";
import SelectBar from "./SelectBar";
const CustomDiv = styled.div`
  .svgDualGraph {
    cursor: move;
    display: block;
    background: #eeeeee;
    width: 100vw;
    height: 100vh;
    background-color: #f2f7fc;
  }
  .edge {
    stroke: #3E68B2;
    stroke-width: 8px;
    stroke-linecap: round;
    stroke-linejoin: round;
    // cursor: default;
  }
  .drag-line {
    stroke: #000;
    stroke-width: 3px; // Fixed typo here, it should be stroke-width not strokewidth
    stroke-linecap: round; // Fixed typo, it should be stroke-linecap not strokelinecap
    stroke-linejoin: round; // Fixed typo, it should be stroke-linejoin not strokelinejoin
    // cursor: default;
  }
  .highlighted {
    stroke: red; /* Highlight color */
    stroke-width: 8px; /* Increase stroke width for visibility */
  }
  .plot {
    // stroke: gray; /* Highlight color */
    stroke-width: 4px; /* Increase stroke width for visibility */
    stroke-linecap: round;
    stroke-dasharray: 10, 10;
    // cursor: default;
  }
  .text {
    font-family: sans-serif;
    font-size: 10px;
    z-index: 1000;
  }
  .vertex {
    cursor: pointer;
  }
  .vertex:hover {
    stroke: #333;
    opacity: 0.8;
  }
  .label {
    text-anchor: middle;
    pointer-events: none;
    font-size: 14px;
    stroke: none;
  }
    .door {
    stroke: #f2f7fc; 
    stroke-width: 5px;
    fill: #f2f7fc; 
    outline: #3E68B2; 
  }
  .window {
    stroke: #3E68B2; 
    stroke-width: 2px;
    fill: #3E68B2; 
  }
`;

const Graph = () => {

  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const walls = useSelector(
    (state: RootState) => state.outputEditorButtonState.walls,
  );
  const rooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.rooms,
  );
  const plot_boundary = useSelector(
    (state: RootState) => state.outputEditorButtonState.plot_boundary,
  );
  const draw = useSelector(
    (state: RootState) => state.outputEditorButtonState.drawLine,
  );
  const windows = useSelector(
    (state: RootState) => state.outputEditorButtonState.drawWindow,
  );
  const doors = useSelector(
    (state: RootState) => state.outputEditorButtonState.drawDoor,
  );
  const windowAssets: Array<{ id: string, x: number, y: number, isVertical: boolean }> | null | undefined = useSelector(
    (state: RootState) => state.outputEditorButtonState.windowAssets,
  );
  const doorAssets: Array<{ id: string, x: number, y: number, isVertical: boolean }> | null | undefined = useSelector(
    (state: RootState) => state.outputEditorButtonState.doorAssets,
  );

  const navigate = useNavigate();
  let wall1: Wall | undefined, wall2: Wall | undefined;

  const drawLine = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    drag: boolean,
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    id: string,
    color: string,
  ) => {
    if (contentGroupRef.current)
      contentGroup = d3.select(contentGroupRef.current);
    if (drag) removeDragLine(contentGroup);

    const line = contentGroup
      .append("line")
      .attr("id", id)
      .attr("class", drag ? "drag-line" : "edge")
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
      .attr("stroke", "#000")
      .attr("stroke-width", 2);
    // .call(lineDrag as any)
    // .on("click", () => handleLineClick(id));
    console.log(line);
    return line;
  };
  const drawDoor = (
    x: number,
    y: number,
    vertical: boolean,
    horizontal: boolean,
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    id: string,
  ) => {
    if (contentGroupRef.current)
      contentGroup = d3.select(contentGroupRef.current);
    console.log("Door Here!")
    const x1: number = vertical ? x : x - 20;
    const x2: number = vertical ? x : x + 20;
    const y1: number = horizontal ? y : y - 20;
    const y2: number = horizontal ? y : y + 20;

    const door = contentGroup
      .append("line")
      .attr("id", id)
      .attr("class", "door")
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
    // .call(lineDrag as any)
    // .on("click", () => handleLineClick(id));
    console.log(door);
    const doorData = {
      id,
      x,
      y,
      isVertical: vertical
    };
    dispatch(setDoorAssets([...(doorAssets || []), doorData]));

    return door;
  };
  const drawWindow = (
    x: number,
    y: number,
    vertical: boolean,
    horizontal: boolean,
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    id: string,
  ) => {
    if (contentGroupRef.current)
      contentGroup = d3.select(contentGroupRef.current);

    console.log("Window Here!")

    const windowLength = 40; // Length of the window
    const windowWidth = 10;  // Width of the white bar

    // Calculate the start and end points based on orientation
    let x1, y1, x2, y2, offset;
    if (vertical) {
      // For vertical walls
      x1 = x;
      y1 = y - windowLength / 2;
      x2 = x;
      y2 = y + windowLength / 2;
      offset = 2; // Small offset for side lines
    } else {
      // For horizontal walls
      x1 = x - windowLength / 2;
      y1 = y;
      x2 = x + windowLength / 2;
      y2 = y;
      offset = 2; // Small offset for side lines
    }

    // Draw the white window bar
    const windowBar = contentGroup
      .append("line")
      .attr("id", id)
      .attr("class", "window")
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)

    // Draw blue/brown lines on the sides
    if (vertical) {
      // Left side line
      contentGroup
        .append("line")
        .attr("class", "window-side-line")
        .attr("x1", x1 - offset)
        .attr("y1", y1)
        .attr("x2", x1 - offset)
        .attr("y2", y2)
        .attr("stroke", "#f2f7fc")
        .attr("stroke-width", 2);

      // Right side line
      contentGroup
        .append("line")
        .attr("class", "window-side-line")
        .attr("x1", x1 + offset)
        .attr("y1", y1)
        .attr("x2", x1 + offset)
        .attr("y2", y2)
        .attr("stroke", "#f2f7fc")
        .attr("stroke-width", 2);
    } else {
      // Top side line
      contentGroup
        .append("line")
        .attr("class", "window-side-line")
        .attr("x1", x1)
        .attr("y1", y1 - offset)
        .attr("x2", x2)
        .attr("y2", y1 - offset)
        .attr("stroke", "#f2f7fc")
        .attr("stroke-width", 2);

      // Bottom side line
      contentGroup
        .append("line")
        .attr("class", "window-side-line")
        .attr("x1", x1)
        .attr("y1", y1 + offset)
        .attr("x2", x2)
        .attr("y2", y1 + offset)
        .attr("stroke", "#f2f7fc")
        .attr("stroke-width", 2);
    }
    dispatch(setWindowAssets([...(windowAssets || []), { id, x, y, isVertical: vertical }]));
    return windowBar;
  };
  const redrawAssets = () => {
    if (contentGroupRef.current) {
      const contentGroup = d3.select(contentGroupRef.current);

      contentGroup.selectAll(".window").remove();
      contentGroup.selectAll(".door").remove();

      windowAssets?.forEach(window => {
        drawWindow(
          window.x,
          window.y,
          window.isVertical,
          !window.isVertical,
          contentGroup,
          window.id
        );
      });

      doorAssets?.forEach(door => {
        drawDoor(
          door.x,
          door.y,
          door.isVertical,
          !door.isVertical,
          contentGroup,
          door.id
        );
      });
    }
  };

  useEffect(() => {
    redrawAssets();
  }, [windows, doors]);

  let dragging: boolean = false;
  let dragStartNode: { id: string; x: number; y: number } | null = null;
  let limits: {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
    minXWall: Wall | undefined;
    maxXWall: Wall | undefined;
    minYWall: Wall | undefined;
    maxYWall: Wall | undefined;
  };
  let contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>;

  const removeDragLine = (
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) => {
    contentGroup?.select(".drag-line").attr("class", "drag-line").remove();
  };

  //
  const svgRef = useRef<SVGSVGElement | null>(null);
  const contentGroupRef = useRef<SVGGElement | null>(null);
  const zoomTransform = useRef<d3.ZoomTransform>(d3.zoomIdentity);
  const dispatch = useDispatch();
  dispatch(setCleanUpRequired(true));
  useRoomCleanUp();
  useFloorPlan(svgRef, contentGroupRef, zoomTransform);

  const newWallLimits = (dragStartNode: {
    id: string;
    x: number;
    y: number;
  }) => {
    let minX: number = Number.MIN_VALUE,
      maxX: number = Number.MAX_VALUE,
      minY: number = Number.MIN_VALUE,
      maxY: number = Number.MAX_VALUE;
    let minXWall: Wall | undefined,
      maxXWall: Wall | undefined,
      minYWall: Wall | undefined,
      maxYWall: Wall | undefined;
    walls?.forEach((wall) => {
      let isHorizontal: boolean = wall.y1 === wall.y2;
      if (isHorizontal) {
        if (
          Math.abs(wall.x1 - dragStartNode.x) +
          Math.abs(wall.x2 - dragStartNode.x) ===
          Math.abs(wall.x1 - wall.x2)
        ) {
          if (wall.y1 > dragStartNode.y) {
            if (maxY > wall.y1) {
              maxY = wall.y1;
              maxYWall = wall;
            }
          } else if (wall.y1 < dragStartNode.y) {
            if (minY < wall.y1) {
              minY = wall.y1;
              minYWall = wall;
            }
          }
        }
      } else {
        if (
          Math.abs(wall.y1 - dragStartNode.y) +
          Math.abs(wall.y2 - dragStartNode.y) ===
          Math.abs(wall.y1 - wall.y2)
        ) {
          if (wall.x1 > dragStartNode.x) {
            if (maxX > wall.x1) {
              maxX = wall.x1;
              maxXWall = wall;
            }
          } else if (wall.x1 < dragStartNode.x) {
            if (minX < wall.x1) {
              minX = wall.x1;
              minXWall = wall;
            }
          }
        }
      }
    });
    return { minX, maxX, minY, maxY, minXWall, maxXWall, minYWall, maxYWall };
  };

  // finding nearest wall in case new line point is very close to the wall, then use the coordinates according to wall
  const nearestWall = (x: number, y: number) => {
    let minDist: number = Number.MAX_VALUE;
    let minDistWall: Wall | undefined;
    walls?.forEach((wall) => {
      let dist: number = Number.MAX_VALUE;
      // vertical
      if ((wall.x1 === wall.x2) && (!wall1 || (wall1 && wall1.x1 === wall1.x2))) {
        dist = Math.abs(x - wall.x1);
      } else if ((wall.y1 === wall.y2) && (!wall1 || (wall1 && wall1.y1 === wall1.y2))) {
        dist = Math.abs(y - wall.y1);
      }
      // keeping buffer of 5 pixels
      if (dist < minDist && dist < 5) {
        minDist = dist;
        minDistWall = wall;
      }
    });
    return minDistWall;
  };

  // to get the room id inside which the wall is drawn
  const getRoom = (newWall: Wall) => {
    const isNewWallHorizontal: boolean = newWall.y1 === newWall.y2;
    if (rooms)
      for (let room of rooms) {
        let isLeft: boolean = false,
          isRight: boolean = false,
          isAbove: boolean = false,
          isBelow: boolean = false;
        room?.walls?.forEach((wall) => {
          let isWallHorizontal: boolean = wall.y1 === wall.y2;
          let overlap: boolean = false;
          if (
            isNewWallHorizontal &&
            isWallHorizontal &&
            Math.max(newWall.x1, newWall.x2, wall.x1, wall.x2) -
            Math.min(newWall.x1, newWall.x2, wall.x1, wall.x2) <=
            Math.abs(newWall.x1 - newWall.x2) + Math.abs(wall.x1 - wall.x2)
          )
            overlap = true;
          else if (
            !isNewWallHorizontal &&
            !isWallHorizontal &&
            Math.max(newWall.y1, newWall.y2, wall.y1, wall.y2) -
            Math.min(newWall.y1, newWall.y2, wall.y1, wall.y2) <=
            Math.abs(newWall.y1 - newWall.y2) + Math.abs(wall.y1 - wall.y2)
          )
            overlap = true;
          else if (
            isNewWallHorizontal &&
            !isWallHorizontal &&
            Math.abs(newWall.y1 - wall.y1) + Math.abs(newWall.y1 - wall.y2) ===
            Math.abs(wall.y1 - wall.y2)
          )
            overlap = true;
          else if (
            !isNewWallHorizontal &&
            isWallHorizontal &&
            Math.abs(newWall.x1 - wall.x1) + Math.abs(newWall.x1 - wall.x2) ===
            Math.abs(wall.x1 - wall.x2)
          )
            overlap = true;

          if (overlap) {
            if (Math.max(newWall.x1, newWall.x2) <= Math.min(wall.x1, wall.x2))
              isLeft = true;
            else if (
              Math.min(newWall.x1, newWall.x2) >= Math.max(wall.x1, wall.x2)
            )
              isRight = true;
            if (Math.max(newWall.y1, newWall.y2) <= Math.min(wall.y1, wall.y2))
              isAbove = true;
            else if (
              Math.min(newWall.y1, newWall.y2) >= Math.max(wall.y1, wall.y2)
            )
              isBelow = true;
          }
        });
        if (isLeft && isRight && isAbove && isBelow) return room.id;
      }
  };

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (draw) {
      // start line
      if (!dragging) {
        let [x, y] = d3.pointer(event, svgRef.current);
        dragging = true;
        wall1 = nearestWall(x, y);
        if (wall1) {
          if (wall1.x1 === wall1.x2) x = wall1.x1;
          else y = wall1.y1;
        }
        dragStartNode = { id: uuidv4(), x, y };
        limits = newWallLimits(dragStartNode);
        drawLine(x, y, x, y, true, contentGroup, dragStartNode.id, "#000");
      }
      // end line
      else if (dragging && dragStartNode) {
        let [x, y] = d3.pointer(event, svgRef.current);
        wall2 = nearestWall(x, y);
        if (wall1 && wall2) {
          if (wall1.x1 === wall1.x2) {
            x = wall2.x1;
            y = dragStartNode.y;
          } else {
            x = dragStartNode.x;
            y = wall2.y1;
          }
        }
        // wall with other end not lying on any pre-existing wall
        else {
          if (Math.abs(dragStartNode.x - x) >= Math.abs(dragStartNode.y - y))
            y = dragStartNode.y;
          else x = dragStartNode.x;
        }

        x = Math.max(Math.min(x, limits.maxX), limits.minX);
        y = Math.max(Math.min(y, limits.maxY), limits.minY);
        if (x === limits.minX) {
          wall2 = limits.minXWall;
        } else if (x === limits.maxX) {
          wall2 = limits.maxXWall;
        } else if (y === limits.minY) {
          wall2 = limits.minYWall;
        } else if (y === limits.maxY) {
          wall2 = limits.maxYWall;
        }

        let line = drawLine(
          dragStartNode.x,
          dragStartNode.y,
          x,
          y,
          false,
          contentGroup,
          dragStartNode.id,
          "#000",
        );
        dragging = false;
        dragStartNode = null;

        let wall: Wall = {
          id: line.attr("id"),
          x1: parseFloat(line.attr("x1")),
          x2: parseFloat(line.attr("x2")),
          y1: parseFloat(line.attr("y1")),
          y2: parseFloat(line.attr("y2")),
          roomIds: [],
        };
        let roomid = getRoom(wall);
        let updatedWalls: Wall[] | undefined = [];
        if (walls) {
          updatedWalls = [...walls];
        }
        let updatedRooms: Room[] | undefined = rooms?.filter(
          (r) => r.id !== roomid,
        );
        if (roomid) {
          wall.roomIds.push(roomid);
          updatedWalls?.push(wall);
          if (wall1 && wall2) {
            let room: Room | undefined = rooms?.find((r) => r.id === roomid);
            updatedWalls = updatedWalls?.filter(
              (w) =>
                !(
                  (w.roomIds.length > 0 && w.roomIds[0] === room?.id) ||
                  (w.roomIds.length > 1 && w.roomIds[1] === room?.id)
                ),
            );
            if (room) {
              let newRoomId: string = uuidv4();
              let newRoomWalls: Wall[] = [wall],
                updatedRoomWalls: Wall[] = [wall];
              wall.roomIds.push(newRoomId);
              updatedWalls?.push(wall);
              room.walls?.forEach((w) => {
                // horizontal wall added
                if (wall.y1 === wall.y2) {
                  if (Math.max(w.y1, w.y2) < wall.y1) {
                    updatedRoomWalls.push(w);
                    updatedWalls?.push(w);
                  } else if (Math.min(w.y1, w.y2) > wall.y1) {
                    let newWall: Wall = {
                      ...w,
                      roomIds: [
                        ...w.roomIds.filter((rid) => rid !== room?.id),
                        newRoomId,
                      ],
                    };
                    newRoomWalls.push(newWall);
                    updatedWalls?.push(newWall);
                  } else {
                    let newWall1: Wall = {
                      ...w,
                      id: uuidv4(),
                      y1: Math.min(w.y1, w.y2),
                      y2: wall.y1,
                    };
                    let newWall2: Wall = {
                      ...w,
                      id: uuidv4(),
                      y1: Math.max(w.y1, w.y2),
                      y2: wall.y1,
                      roomIds: [
                        ...w.roomIds.filter((rid) => rid !== room?.id),
                        newRoomId,
                      ],
                    };
                    updatedRoomWalls.push(newWall1);
                    newRoomWalls.push(newWall2);
                    updatedWalls?.push(newWall1);
                    updatedWalls?.push(newWall2);
                    let adjRoomId: string | undefined = w.roomIds.find(
                      (rid) => rid !== room?.id,
                    );
                    if (adjRoomId) {
                      let adjRoom: Room | undefined = rooms?.find(
                        (r) => r.id === adjRoomId,
                      );
                      if (adjRoom) {
                        let updatedAdjRoomWalls: Wall[] | undefined =
                          adjRoom.walls?.filter((aw) => aw.id !== w.id);
                        if (updatedAdjRoomWalls) {
                          updatedAdjRoomWalls.push(newWall1);
                          updatedAdjRoomWalls.push(newWall2);
                        }
                        adjRoom = { ...adjRoom, walls: updatedAdjRoomWalls };
                        updatedRooms = updatedRooms?.filter(
                          (r) => r.id !== adjRoomId,
                        );
                        updatedRooms?.push(adjRoom);
                      }
                    }
                  }
                }
                // vertical wall added
                else {
                  if (Math.max(w.x1, w.x2) < wall.x1) {
                    updatedRoomWalls.push(w);
                    updatedWalls?.push(w);
                  } else if (Math.min(w.x1, w.x2) > wall.x1) {
                    let newWall: Wall = {
                      ...w,
                      roomIds: [
                        ...w.roomIds.filter((rid) => rid !== room?.id),
                        newRoomId,
                      ],
                    };
                    newRoomWalls.push(newWall);
                    updatedWalls?.push(newWall);
                  } else {
                    let newWall1: Wall = {
                      ...w,
                      x1: Math.min(w.x1, w.x2),
                      x2: wall.x1,
                    };
                    let newWall2: Wall = {
                      ...w,
                      x1: Math.max(w.x1, w.x2),
                      x2: wall.x1,
                      roomIds: [
                        ...w.roomIds.filter((rid) => rid !== room?.id),
                        newRoomId,
                      ],
                    };
                    updatedRoomWalls.push(newWall1);
                    newRoomWalls.push(newWall2);
                    updatedWalls?.push(newWall1);
                    updatedWalls?.push(newWall2);
                    let adjRoomId: string | undefined = w.roomIds.find(
                      (rid) => rid !== room?.id,
                    );
                    if (adjRoomId) {
                      let adjRoom: Room | undefined = rooms?.find(
                        (r) => r.id === adjRoomId,
                      );
                      if (adjRoom) {
                        let updatedAdjRoomWalls: Wall[] | undefined =
                          adjRoom.walls?.filter((aw) => aw.id !== w.id);
                        if (updatedAdjRoomWalls) {
                          updatedAdjRoomWalls.push(newWall1);
                          updatedAdjRoomWalls.push(newWall2);
                        }
                        adjRoom = { ...adjRoom, walls: updatedAdjRoomWalls };
                        updatedRooms = updatedRooms?.filter(
                          (r) => r.id !== adjRoomId,
                        );
                        updatedRooms?.push(adjRoom);
                      }
                    }
                  }
                }
              });
              room = { ...room, walls: updatedRoomWalls };
              let newRoom: Room = {
                id: newRoomId,
                name: `${(rooms?.length || 1) + 1}`,
                walls: newRoomWalls,
              };
              updatedRooms?.push(room);
              updatedRooms?.push(newRoom);
            }
          } else {
            let room: Room | undefined = rooms?.find((r) => r.id === roomid);
            if (room) {
              let updatedRoomWalls: Wall[] | undefined = room.walls;
              if (updatedRoomWalls)
                updatedRoomWalls = [...updatedRoomWalls, wall];
              room = { ...room, walls: updatedRoomWalls };
              updatedRooms?.push(room);
            }
          }
        }

        dispatch(clearRedoStacks());
        dispatch(pushUndoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }));
        if (updatedWalls) dispatch(setWalls(updatedWalls));
        if (updatedRooms) dispatch(setRooms(updatedRooms));
        dispatch(setCleanUpDone(true));

        wall1 = undefined;
        wall2 = undefined;
      }

    }
    else if (windows) {
      let [x, y] = d3.pointer(event, svgRef.current);
      wall1 = nearestWall(x, y);
      console.log(wall1);
      dragStartNode = { id: uuidv4(), x, y };
      if (wall1) {
        let isVertical = false;
        let isHorizontal = false;

        if (wall1.x1 === wall1.x2) {
          x = wall1.x1;
          isVertical = true;
          drawWindow(
            x,
            y,
            isVertical,
            isHorizontal,
            contentGroup,
            dragStartNode.id,
          );
        } else {
          y = wall1.y1;
          isHorizontal = true;
          drawWindow(
            x,
            y,
            isVertical,
            isHorizontal,
            contentGroup,
            dragStartNode.id,
          );
        }
      }
    }
    else if (doors) {
      let [x, y] = d3.pointer(event, svgRef.current);
      wall1 = nearestWall(x, y);
      // console.log(wall1);
      dragStartNode = { id: uuidv4(), x, y };
      if (wall1) {
        let isVertical = false;
        let isHorizontal = false;

        if (wall1.x1 === wall1.x2) {
          x = wall1.x1;
          isVertical = true;
          drawDoor(
            x,
            y,
            isVertical,
            isHorizontal,
            contentGroup,
            dragStartNode.id,
          );
        } else {
          y = wall1.y1;
          isHorizontal = true;
          drawDoor(
            x,
            y,
            isVertical,
            isHorizontal,
            contentGroup,
            dragStartNode.id,
          );
        }
      }
    }
  };

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (draw) {
      if (dragging && dragStartNode) {
        let [x, y] = d3.pointer(event, svgRef.current);
        if (wall1) {
          if (wall1.x1 === wall1.x2) y = dragStartNode.y;
          else x = dragStartNode.x;
        } else {
          if (Math.abs(dragStartNode.x - x) >= Math.abs(dragStartNode.y - y))
            y = dragStartNode.y;
          else x = dragStartNode.x;
        }
        x = Math.max(Math.min(x, limits.maxX), limits.minX);
        y = Math.max(Math.min(y, limits.maxY), limits.minY);
        drawLine(
          dragStartNode.x,
          dragStartNode.y,
          x,
          y,
          true,
          contentGroup,
          "1",
          "#000",
        );
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <CustomDiv>
        {
          <svg
            ref={svgRef}
            className="svgDualGraph"
            style={{ cursor: draw ? "crosshair" : "move" }}
            width={visualViewport?.width}
            height={visualViewport?.height}
            onClick={handleClick}
            onMouseMoveCapture={handleMouseMove}
          ></svg>
        }
        <div
          style={{ position: "fixed", zIndex: 1000, right: "8px", top: "2px" }}
        ></div>
      </CustomDiv>
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          top: "100px",
          left: "50px",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "10px 17px",
          cursor: "pointer",
          // transition: "opacity 0.3s", // Transition for opacity change
          // "&:hover": {
          //   opacity: 0.8, // Change opacity on hover
          // },
        }}
        onClick={() => {
          navigate("/inputeditor");
        }}
      >
        <ArrowBackIcon
          htmlColor="#1C4C82"
          sx={{
            padding: "3px",
          }}
        />
        <Typography
          sx={{
            pl: "5px",
            color: "#1C4C82",
            textAlign: "left",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Back
        </Typography>
      </Box>
      <TopNav svgRef={svgRef} />
      {/* <SelectBar /> */}
    </div>
  );
};

export default Graph;
