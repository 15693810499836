import { useCallback, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { Room, Wall, WallWithoutId } from "../components/Floorplan";
import {
  drawGrid,
  getWallLimits,
  getRandomColor,
  getRoomLimits,
  getFloorplanLimits,
} from "../utils/helpers";
import { LINEWIDTH } from "../utils/constants";
import {
  clearRedoStacks,
  pushUndoStacks,
  setAssetType,
  setCleanUpDone,
  setCleanUpRequired,
  setPlot,
  setRooms,
  setSelectedWall,
  setSelectedWallOption,
  setWalls,
} from "../../../redux/features/appState";
import { v4 as uuidv4 } from "uuid";
import door from "../../../assets/inputGraphEditor/door.svg";
import door90 from "../../../assets/inputGraphEditor/door90.svg";
import { useRoomCleanUp } from "./useRoomCleanup";
import { formatLabel } from "../../../utils/helpers";
import { showToast } from "../../../redux/features/toast/toast.slice";

export const useFloorPlan = (
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  contentGroupRef: React.MutableRefObject<SVGGElement | null>,
  zoomTransform: React.MutableRefObject<d3.ZoomTransform>,
) => {
  const [selectedLineId, setSelectedLineId] = useState<string>();
  // const floorPlans = useSelector((state: RootState) => state.graph.floorPlans);
  const index = useSelector((state: RootState) => state.graph.index);
  // const floorPlan = floorPlans[index];
  const dimensioned = useSelector(
    (state: RootState) => state.graph.dimensioned,
  );
  const cleanUpDone = useSelector(
    (state: RootState) => state.outputEditorButtonState.cleanUpDone,
  );
  const newAsset = useSelector(
    (state: RootState) => state.outputEditorButtonState.addAsset,
  );
  let contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>;
  const rooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.rooms,
  );
  const floorPlans = useSelector((state: RootState) => state.graph.floorPlans);
  const walls = useSelector(
    (state: RootState) => state.outputEditorButtonState.walls,
  );
  const labels = useSelector(
    (state: RootState) => state.outputEditorButtonState.labels,
  );
  const showPlot = useSelector(
    (state: RootState) => state.outputEditorButtonState.showPlot,
  );
  const plot_boundary = useSelector(
    (state: RootState) => state.outputEditorButtonState.plot_boundary,
  );
  const draw = useSelector(
    (state: RootState) => state.outputEditorButtonState.drawLine,
  );
  const unit = useSelector(
    (state: RootState) => state.outputEditorButtonState.unit,
  );
  const selectedWall = useSelector(
    (state: RootState) => state.outputEditorButtonState.selectedWall,
  );
  const selectedWallOption = useSelector(
    (state: RootState) => state.outputEditorButtonState.selectedWallOption,
  );
  const selectedRef = useRef(selectedWallOption === "select"); // Ref to track selected
  const selectedWallRef = useRef(selectedWall); // Ref to track selected

  useEffect(() => {
    if (setSelectedWallOption !== null) {
      selectedWallRef.current = selectedWall;
      selectedRef.current = selectedWallOption === "select"; // Keep the ref updated with the current value of selected
      // dispatch(setSelectedWallOption(null));
    }
  }, [selectedWallOption]);

  const dispatch = useDispatch<AppDispatch>();
  const drawnWallsRef: React.MutableRefObject<WallWithoutId[]> = useRef([]);
  const tempRoomsRef: React.MutableRefObject<Room[]> = useRef([]);

  const getViewportDimension = (isWidth: boolean): number => {
    if (typeof window === "undefined" || !window.visualViewport) {
      return isWidth ? 1920 : 1080; // Fallback values
    }
    return isWidth ? window.visualViewport.width : window.visualViewport.height;
  };

  // Updated scaling function
  const scaleCoordinate = (
    coord: number,
    isX: boolean,
    width: number,
    height: number,
  ): number => {
    const viewportDimension = getViewportDimension(isX);
    const offset = viewportDimension * 0.3;
    const scaleFactor = (getViewportDimension(false) * 0.5) / height;
    return coord * scaleFactor + offset;
  };

  // Updated unscaling function
  const unscaleCoordinate = (
    scaledCoord: number,
    isX: boolean,
    width: number,
    height: number,
  ): number => {
    const viewportDimension = getViewportDimension(isX);
    const offset = viewportDimension * 0.3;
    const scaleFactor = (getViewportDimension(false) * 0.5) / height;
    return (scaledCoord - offset) / scaleFactor;
  };

  // Function to calculate wall length using unscaled coordinates
  const calculateUnscaledWallLength = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    width: number,
    height: number,
  ): number => {
    const unscaledX1 = unscaleCoordinate(x1, true, width, height);
    const unscaledY1 = unscaleCoordinate(y1, false, width, height);
    const unscaledX2 = unscaleCoordinate(x2, true, width, height);
    const unscaledY2 = unscaleCoordinate(y2, false, width, height);

    return Math.sqrt(
      Math.pow(unscaledX2 - unscaledX1, 2) +
      Math.pow(unscaledY2 - unscaledY1, 2),
    );
  };

  const convertUnitsToMeters = (units: number): string => {
    const meters = units * 0.3048; // Convert feet to meters
    return `${meters.toFixed(2)} m`; // Round to 2 decimal places for readability
  };

  // Conversion function (assuming input is in the original units, likely feet)
  const convertUnitsToFeetAndInches = (units: number): string => {
    const feet = Math.floor(units);
    const inches = Math.round((units - feet) * 12);

    if (inches === 12) {
      return `${feet + 1}' 0"`;
    }

    return `${feet}' ${inches}"`;
  };

  useEffect(() => {
    // console.log("doRoomCleanUp 2", cleanUpDone);
    if (!cleanUpDone) return;
    if (!svgRef.current) return;
    if (!svgRef.current.viewBox.baseVal) return;
    drawnWallsRef.current = [];

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3
      .select(svgRef.current)
      .attr("width", "100%")
      .attr("height", "100%");

    // Calculate scale factor based on the window size

    const scaleX =
      (window.innerWidth * 15) / svgRef.current.viewBox.baseVal.width;
    const scaleY =
      (window.innerHeight * 15) / svgRef.current.viewBox.baseVal.height;
    const scale = Math.min(scaleX, scaleY);
    // Create a group that will contain everything
    const mainGroup = svg.append("g").attr("class", "main-group");
    // .attr("transform", `scale(${scale})`);

    // Grid group
    const gridGroup = mainGroup.append("g").attr("class", "grid-group");
    drawGrid(gridGroup, svgRef);

    // Content group
    const floorplanGroup = mainGroup
      .append("g")
      .attr("class", "floorplan-group");
    // .attr("transform", `scale(${scale})`);

    // Calculate translation to center the floorplan
    const scaleFactor = 1;
    const svgWidth = svgRef.current.viewBox.baseVal.width;
    const svgHeight = svgRef.current.viewBox.baseVal.height;

    // Calculate translation to center the scaled floorplan
    const translateX = (svgWidth * (1 - scaleFactor)) / 2;
    const translateY = (svgHeight * (1 - scaleFactor)) / 2;
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;
    walls?.forEach((wall) => {
      minX = Math.min(minX, wall.x1, wall.x2);
      minY = Math.min(minY, wall.y1, wall.y2);
      maxX = Math.max(maxX, wall.x1, wall.x2);
      maxY = Math.max(maxY, wall.y1, wall.y2);
    });

    // Calculate the center of the floorplan
    const floorplanCenterX = (minX + maxX) / 2;
    const floorplanCenterY = (minY + maxY) / 2;
    // Content group (inside floorplan group)
    contentGroupRef.current = floorplanGroup
      .append("g")
      .attr("class", "content-group")
      .node();

    // contentGroupRef?.current?.attr("transform", `scale(${scale})`);
    // Define and apply zoom behavior
    const zoomBehavior = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.5, 2])
      .translateExtent([
        [0, 0],
        [window.innerWidth, window.innerHeight],
      ])
      .on("zoom", (event) => {
        zoomTransform.current = event.transform;
        mainGroup.attr("transform", event.transform);
      });

    svg.call(zoomBehavior).on("dblclick.zoom", null).on("mousedown.zoom", null);
    // svg.call(dra)

    const drag = d3
      .drag<SVGSVGElement, unknown>()
      .on("start", () => {
        svg.on(".zoom", null);
      })
      .on("drag", (event) => {
        zoomTransform.current = d3.zoomTransform(
          svgRef.current as SVGSVGElement,
        );
        mainGroup.attr("transform", () => {
          return `translate(${zoomTransform.current.x + event.dx},${zoomTransform.current.y + event.dy
            }) scale(${zoomTransform.current.k})`;
        });
      })
      .on("end", () => {
        svg.call(zoomBehavior);
      });
    // Helper function to safely get viewport dimensions

    // Updated drawWallDimensions function
    const drawWallDimensions = (
      contentGroup: d3.Selection<SVGGElement | null, unknown, null, undefined>,
      x1: number,
      y1: number,
      x2: number,
      y2: number,

      width: number,
      height: number,
    ): void => {
      const wallLength = calculateUnscaledWallLength(
        x1,
        y1,
        x2,
        y2,
        width,
        height,
      );
      const dimensionText =
        unit === "meters"
          ? convertUnitsToMeters(wallLength)
          : convertUnitsToFeetAndInches(wallLength);
      // Calculate midpoint for placing the dimension text
      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2;

      // Adjust the text position slightly to avoid overlap with the wall
      const offset = 10; // Adjust this value based on your design

      const dx = x2 - x1;
      const dy = y2 - y1;
      const wallAngle = Math.atan2(dy, dx);
      const textX = midX + offset * Math.cos(wallAngle + Math.PI / 2);
      const textY = midY + offset * Math.sin(wallAngle + Math.PI / 2);

      // Add the dimension text
      contentGroup
        .append("text")
        .attr("x", textX)
        .attr("y", textY)
        .attr("fill", "#000")
        .attr("font-size", "12px")
        .attr("font-weight", "bold")
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")

        .attr(
          "transform",
          `rotate(${(wallAngle * 180) / Math.PI}, ${textX}, ${textY})`,
        )

        .text(dimensionText);
    };

    let minXWall = 1e9;
    let maxXWall = -1e9;
    let minYWall = 1e9;
    let maxYWall = -1e9;

    floorPlans[index].forEach((apiRoom) => {
      apiRoom?.walls?.forEach((apiWall: any) => {
        minXWall = Math.min(apiWall.x1, apiWall.x2, minXWall);
        minYWall = Math.min(apiWall.y1, apiWall.y2, minYWall);
        maxXWall = Math.max(apiWall.x1, apiWall.x2, maxXWall);
        maxYWall = Math.max(apiWall.y1, apiWall.y2, maxYWall);
      });
    });

    const width = Math.abs(maxXWall - minXWall);
    const height = Math.abs(maxYWall - minYWall);
    svg.call(drag);
    // console.log(drawnWallsRef.current);
    walls?.forEach((wall) => {
      // ########################### MIGHT CAUSE ISSUES ####################
      const room = rooms?.find((r) => wall?.roomIds?.includes(r.id));
      // ########################### MIGHT CAUSE ISSUES ####################
      drawLine(
        wall.x1,
        wall.y1,
        wall.x2,
        wall.y2,
        false,
        //@ts-ignore
        d3.select(contentGroupRef.current),
        wall.id,
        false,
      );
      drawWallDimensions(
        d3.select(contentGroupRef.current),
        wall.x1,
        wall.y1,
        wall.x2,
        wall.y2,
        width,
        height,
      );
    });
    labels?.forEach((label) => {
      addRoomLabel(label.x, label.y, label.text);
    });

    if (showPlot) {
      plot_boundary?.forEach((boundary) => {
        drawLine(
          boundary.x1,
          boundary.y1,
          boundary.x2,
          boundary.y2,
          false,
          //@ts-ignore
          d3.select(contentGroupRef.current),
          boundary.id,
          true,
        );
        drawWallDimensions(
          d3.select(contentGroupRef.current),
          boundary.x1,
          boundary.y1,
          boundary.x2,
          boundary.y2,
          width,
          height,
        );
      });
    }

    dispatch(setCleanUpDone(false));
    dispatch(setCleanUpRequired(false));
  }, [cleanUpDone, dimensioned]);
  useEffect(() => {
    dispatch(setCleanUpDone(true));
  }, [dispatch, unit]);
  useEffect(() => {
    // console.log("walls", walls);
  }, [walls, unit]);
  useEffect(() => {
    // console.log("rooms", rooms);
  }, [rooms]);
  //@ts-ignore
  const lineDrag: d3.DragBehavior<Element, unknown, unknown> = d3
    .drag<SVGLineElement, unknown>()
    .on("start", function (event) {
      if (selectedRef.current && !draw) {
        d3.select(this).raise();
        //event.sourceEvent.stopPropagation(); // Prevent click event from firing immediately
        // @ts-ignore
        this.dragStartTime = new Date().getTime();
        const line = d3.select(this);
        const wall = walls?.find((wall) => wall.id === line.attr("id"));
        const plot_wall = plot_boundary?.find(
          (wall) => wall.id === line.attr("id"),
        );
        if (wall === selectedWallRef.current || plot_wall === selectedWallRef.current)
          line.attr("stroke", "red");
      }
    })
    .on("drag", function (event) {
      if (selectedRef.current && !draw) {
        const line = d3.select(this);
        const wall = walls?.find((wall) => wall.id === line.attr("id"));
        const plot_wall = plot_boundary?.find(
          (wall) => wall.id === line.attr("id"),
        );
        if (wall === selectedWallRef.current || plot_wall === selectedWallRef.current) {

          let minX: number = 0,
            maxX: number = 0,
            minY: number = 0,
            maxY: number = 0;
          // wall limits
          if (wall && rooms) {
            const a = getWallLimits(wall, rooms, plot_boundary, showPlot);
            minX = a.minX;
            minY = a.minY;
            maxX = a.maxX;
            maxY = a.maxY;
          }
          // plot boundary limits
          else if (plot_wall && rooms) {
            const a = getFloorplanLimits(rooms);
            minX = plot_wall.x1 >= a.maxFPX ? a.maxFPX : Number.MIN_VALUE;
            maxX = plot_wall.x1 <= a.minFPX ? a.minFPX : Number.MAX_VALUE;
            minY = plot_wall.y1 >= a.maxFPY ? a.maxFPY : Number.MIN_VALUE;
            maxY = plot_wall.y1 <= a.minFPY ? a.minFPY : Number.MAX_VALUE;
            minX += 5;
            maxX -= 5;
            minY += 5;
            maxY -= 5;
          }
          const dx = event.dx,
            dy = event.dy;
          // vertical line constrained to move horizontally
          if (line.attr("x1") === line.attr("x2")) {
            const newX = Math.min(
              Math.max(parseFloat(line.attr("x1")) + dx, minX),
              maxX,
            );
            line.attr("x1", newX).attr("x2", newX);
          }
          // horizontal line constrained to move vertically
          else {
            const newY = Math.min(
              Math.max(parseFloat(line.attr("y1")) + dy, minY),
              maxY,
            );
            line.attr("y1", newY).attr("y2", newY);
          }
        }
      }
    })
    .on("end", function (event, d) {
      if (selectedRef.current && !draw) {
        const line = d3.select(this);
        const lineId = line.attr("id");
        const dragEndTime = new Date().getTime();
        // @ts-ignore
        const dragDuration = dragEndTime - this.dragStartTime;
        if (dragDuration < 250) {
          // Consider it a click if the drag lasted less than 200ms
          handleLineClick(lineId);
        } else {
          const newPositions = {
            id: lineId,
            x1: parseFloat(line.attr("x1")),
            y1: parseFloat(line.attr("y1")),
            x2: parseFloat(line.attr("x2")),
            y2: parseFloat(line.attr("y2")),
          };
          const wall = walls?.find((w) => w.id === lineId);
          if (wall) {
            const updatedWall: Wall = {
              ...wall,
              x1: newPositions.x1,
              x2: newPositions.x2,
              y1: newPositions.y1,
              y2: newPositions.y2,
            };
            let obj = { updatedWalls: walls, updatedRooms: rooms };
            updateWall(wall, updatedWall, obj);
            updateWalls(wall, updatedWall, obj);
          }
          // Update the wall color
          // const room = rooms?.find((r) => r.id === wall.roomId);
          // line.attr("stroke", room?.color || "#000000");
          const plot_wall = plot_boundary?.find(
            (wall) => wall.id === line.attr("id"),
          );
          if (plot_wall && plot_boundary) {
            const updatedPlotWall: Wall = {
              ...plot_wall,
              x1: newPositions.x1,
              x2: newPositions.x2,
              y1: newPositions.y1,
              y2: newPositions.y2,
            };
            updatePlotBoundary(plot_wall, updatedPlotWall);
            // let updatedPlotBoundary: Wall[] = plot_boundary?.filter((wall) => wall.id !== line.attr("id"));
            // updatedPlotBoundary = [...updatedPlotBoundary, updatedPlotWall];
            // dispatch(setPlot(updatedPlotBoundary));
            // dispatch(setCleanUpDone(true));
          }
        }
        labels?.forEach((label) => {
          addRoomLabel(label.x, label.y, label.text);
        });
        // Check if it's a click based on duration
      }
      dispatch(setSelectedWallOption(null));
    });

  // delete wall on double click
  // d3.selectAll("line").on("dblclick", function (event) {
  //   const line = d3.select(this);
  //   line.remove();
  //   const wall: Wall | undefined = walls?.find((w) => w.id === line.attr("id"));
  //   if (wall) deleteWall(wall);
  // });

  const deleteWall = (wall: Wall) => {
    dispatch(clearRedoStacks());
    dispatch(pushUndoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }));
    if (wall.roomIds.length > 0) {
      let updatedRooms: Room[] = [];
      let updatedWalls: Wall[];
      // max two rooms will share a common wall
      let idx1: number = -1,
        idx2: number = -1;
      if (walls) {
        updatedWalls = [];
        if (rooms) {
          rooms?.forEach((room) => {
            if (wall?.roomIds.includes(room.id)) {
              if (idx1 === -1) idx1 = rooms.indexOf(room);
              else if (idx2 === -1) idx2 = rooms.indexOf(room);
            }
          });

          let tempWalls: Wall[] = walls;

          // merge the walls of room1 and room2 if they can form a larger wall
          if (idx2 > 0) {
            rooms[idx1]?.walls?.forEach((w1) => {
              let w1Horizontal: boolean = w1.y1 === w1.y2;
              rooms[idx2]?.walls?.forEach((w2) => {
                // only merge if these walls are not common to other rooms, as in that case they must remain split
                if (
                  w1 !== w2 &&
                  ((w1.roomIds.length === 1 && w2.roomIds.length === 1) ||
                    (w1.roomIds.includes(rooms[idx2].id) &&
                      w2.roomIds.includes(rooms[idx1].id)))
                ) {
                  let w2Horizontal: boolean = w2.y1 === w2.y2;
                  if (w1Horizontal === w2Horizontal) {
                    if (w1Horizontal) {
                      // check if they are adjacent walls and can be merged into one
                      if (
                        w1.y1 === w2.y1 &&
                        Math.abs(w1.x1 - w1.x2) + Math.abs(w2.x1 - w2.x2) ===
                        Math.max(w1.x1, w1.x2, w2.x1, w2.x2) -
                        Math.min(w1.x1, w1.x2, w2.x1, w2.x2)
                      ) {
                        tempWalls = tempWalls.filter((w) => w !== w1 && w !== w2);
                        let newWall: Wall = {
                          ...w1,
                          x1: Math.min(w1.x1, w1.x2, w2.x1, w2.x2),
                          x2: Math.max(w1.x1, w1.x2, w2.x1, w2.x2),
                        };
                        tempWalls.push(newWall);
                      }
                    } else {
                      if (
                        w1.x1 === w2.x1 &&
                        Math.abs(w1.y1 - w1.y2) + Math.abs(w2.y1 - w2.y2) ===
                        Math.max(w1.y1, w1.y2, w2.y1, w2.y2) -
                        Math.min(w1.y1, w1.y2, w2.y1, w2.y2)
                      ) {
                        tempWalls = tempWalls.filter((w) => w !== w1 && w !== w2);
                        let newWall: Wall = {
                          ...w1,
                          y1: Math.min(w1.y1, w1.y2, w2.y1, w2.y2),
                          y2: Math.max(w1.y1, w1.y2, w2.y1, w2.y2),
                        };
                        tempWalls.push(newWall);
                      }
                    }
                  }
                }
              });
            });
          }

          // if two rooms share the wall, then merge both the rooms
          tempWalls?.forEach((w) => {
            if (wall.id !== w.id) {
              let updatedRoomIds: string[] = w.roomIds;
              if (idx2 > 0 && w.roomIds.includes(rooms[idx2].id)) {
                updatedRoomIds = w.roomIds.filter(
                  (rid) => rid !== rooms[idx2].id,
                );
                if (!w.roomIds.includes(rooms[idx1].id)) {
                  updatedRoomIds.push(rooms[idx1].id);
                }
              }
              w = { ...w, roomIds: updatedRoomIds };
              updatedWalls.push(w);
            }
          });

          rooms?.forEach((room) => {
            if ((idx2 > 0 && rooms[idx2].id !== room.id) || idx2 === -1) {
              let roomWalls: Wall[] = updatedWalls.filter((w) =>
                w.roomIds.includes(room.id),
              );
              room = { ...room, walls: roomWalls };
              updatedRooms.push(room);
            }
          });

          dispatch(setWalls(updatedWalls));
          dispatch(setRooms(updatedRooms));
          dispatch(setCleanUpDone(true));
        }
      }
    }
    else {
      if (plot_boundary) {
        let updatedPlotBoundary: Wall[] = plot_boundary?.filter(w => w !== wall);
        dispatch(setPlot(updatedPlotBoundary));
        dispatch(setCleanUpDone(true));
      }
    }
  };

  const updateRoom = (
    oldWall: Wall,
    newWall: Wall,
    obj: any,
    remove: boolean,
  ) => {
    let updatedWalls: Wall[] = obj.updatedWalls;
    if (remove) {
      updatedWalls = updatedWalls.filter((w) => w !== oldWall);
    }
    obj.updatedRooms = [];
    rooms?.forEach((room) => {
      let roomWalls: Wall[] = updatedWalls.filter((w) =>
        w.roomIds.includes(room.id),
      );
      room = { ...room, walls: roomWalls };
      obj.updatedRooms.push(room);
    });
  };

  const updateWall = (oldWall: Wall, newWall: Wall, obj: any) => {
    obj.updatedWalls = obj.updatedWalls.filter((w: any) => w.id !== oldWall.id);
    obj.updatedWalls.push(newWall);
    updateRoom(oldWall, newWall, obj, true);
  };

  const updateWalls = (oldWall: Wall, newWall: Wall, obj: any) => {
    dispatch(clearRedoStacks());
    dispatch(
      pushUndoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }),
    );
    walls?.forEach((wall) => {
      if (wall !== oldWall) {
        let oldwallHorizontal: boolean = oldWall.y1 === oldWall.y2;
        let wallHorizontal: boolean = wall.y1 === wall.y2;
        let arePerpendicular: boolean = oldwallHorizontal !== wallHorizontal;
        let oldwallUpwards: boolean =
          oldWall.y1 <= wall.y1 && oldWall.y1 <= wall.y2;
        let oldwallLeftwards: boolean =
          oldWall.x1 <= wall.x1 && oldWall.x1 <= wall.x2;

        // check for perpendicular walls
        if (arePerpendicular) {
          let intersect: boolean = false;
          let flag: boolean = false;

          let x: number, y: number;
          if (
            (wall.x1 === oldWall.x1 && wall.y1 === oldWall.y1) ||
            (wall.x1 === oldWall.x2 && wall.y1 === oldWall.y2)
          ) {
            x = wall.x1;
            y = wall.y1;
            intersect = true;
          } else if (
            (wall.x2 === oldWall.x1 && wall.y2 === oldWall.y1) ||
            (wall.x2 === oldWall.x2 && wall.y2 === oldWall.y2)
          ) {
            x = wall.x2;
            y = wall.y2;
            intersect = true;
          }
          if (!intersect) return;

          // ------------------------------ REPLACE WITH MAP --------------------------------

          // check if there is a third wall (perpendicular to wall) apart from oldwall and wall, with the same intersection point
          // if there is not, then just update wall coordinates with newwall coordinates, and do not create a "new" wall
          walls?.forEach((w) => {
            if (w !== oldWall && w !== wall) {
              let wHorizontal: boolean = w.y1 === w.y2;
              if (
                wallHorizontal !== wHorizontal &&
                ((w.x1 === x && w.y1 === y) || (w.x2 === x && w.y2 === y))
              )
                flag = true;
            }
          });
          // -------------------------------------------------------------------------------

          if (oldwallHorizontal) {
            let mnY: number = Number.MIN_VALUE,
              mxY: number = Number.MAX_VALUE;
            wall.roomIds.forEach((rid) => {
              let room = rooms?.find((r) => r.id === rid);
              room?.walls?.forEach((w) => {
                // if w is in continuation with wall and intersects with oldwall
                if (
                  w !== wall &&
                  w.x1 === w.x2 &&
                  w.x1 === wall.x1 &&
                  Math.abs(w.y1 - oldWall.y1) + Math.abs(w.y2 - oldWall.y1) ===
                  Math.abs(w.y1 - w.y2)
                ) {
                  if (
                    Math.abs(w.y1 - w.y2) + Math.abs(wall.y1 - wall.y2) ===
                    Math.max(w.y1, w.y2, wall.y1, wall.y2) -
                    Math.min(w.y1, w.y2, wall.y1, wall.y2)
                  ) {
                    mnY = Math.min(w.y1, w.y2);
                    mxY = Math.max(w.y1, w.y2);
                  }
                }
                // if w is in continuation with wall but doesn't intersect with oldwall
                else if (
                  w !== wall &&
                  w.x1 === w.x2 &&
                  w.x1 === wall.x1 &&
                  ((oldwallUpwards && newWall.y1 > oldWall.y1) ||
                    (!oldwallUpwards && newWall.y1 < oldWall.y1))
                ) {
                  if (
                    Math.abs(w.y1 - w.y2) + Math.abs(wall.y1 - wall.y2) ===
                    Math.max(w.y1, w.y2, wall.y1, wall.y2) -
                    Math.min(w.y1, w.y2, wall.y1, wall.y2)
                  ) {
                    mnY = Math.min(w.y1, w.y2);
                    mxY = Math.max(w.y1, w.y2);
                    const updatedWall: Wall = {
                      ...w,
                      y1: oldwallUpwards
                        ? Math.max(w.y1, w.y2)
                        : Math.min(w.y1, w.y2),
                      y2: oldwallUpwards
                        ? Math.max(mnY, newWall.y1)
                        : Math.min(mxY, newWall.y1),
                    };
                    updateWall(w, updatedWall, obj);
                  }
                }
              });
            });

            // nested ternary operators check if the wall crosses some other wall then the updated wall should not overlap with the wall being crossed
            if (wall.x1 === oldWall.x1 && wall.y1 === oldWall.y1) {
              const updatedWall: Wall = {
                ...wall,
                y1:
                  !flag ||
                    (oldwallUpwards && newWall.y1 > oldWall.y1) ||
                    (!oldwallUpwards && newWall.y1 < oldWall.y1)
                    ? !flag && oldwallUpwards && newWall.y1 < oldWall.y1
                      ? Math.max(newWall.y1, mnY)
                      : !flag && !oldwallUpwards && newWall.y1 > oldWall.y1
                        ? Math.min(newWall.y1, mxY)
                        : newWall.y1
                    : oldWall.y1,
              };
              updateWall(wall, updatedWall, obj);
            } else if (wall.x2 === oldWall.x1 && wall.y2 === oldWall.y1) {
              const updatedWall: Wall = {
                ...wall,
                y2:
                  !flag ||
                    (oldwallUpwards && newWall.y1 > oldWall.y1) ||
                    (!oldwallUpwards && newWall.y1 < oldWall.y1)
                    ? !flag && oldwallUpwards && newWall.y1 < oldWall.y1
                      ? Math.max(newWall.y1, mnY)
                      : !flag && !oldwallUpwards && newWall.y1 > oldWall.y1
                        ? Math.min(newWall.y1, mxY)
                        : newWall.y1
                    : oldWall.y1,
              };
              updateWall(wall, updatedWall, obj);
            } else if (wall.x1 === oldWall.x2 && wall.y1 === oldWall.y2) {
              const updatedWall: Wall = {
                ...wall,
                y1:
                  !flag ||
                    (oldwallUpwards && newWall.y2 > oldWall.y2) ||
                    (!oldwallUpwards && newWall.y2 < oldWall.y2)
                    ? !flag && oldwallUpwards && newWall.y2 < oldWall.y2
                      ? Math.max(newWall.y2, mnY)
                      : !flag && !oldwallUpwards && newWall.y2 > oldWall.y2
                        ? Math.min(newWall.y2, mxY)
                        : newWall.y2
                    : oldWall.y2,
              };
              updateWall(wall, updatedWall, obj);
            } else if (wall.x2 === oldWall.x2 && wall.y2 === oldWall.y2) {
              const updatedWall: Wall = {
                ...wall,
                y2:
                  !flag ||
                    (oldwallUpwards && newWall.y2 > oldWall.y2) ||
                    (!oldwallUpwards && newWall.y2 < oldWall.y2)
                    ? !flag && oldwallUpwards && newWall.y2 < oldWall.y2
                      ? Math.max(newWall.y2, mnY)
                      : !flag && !oldwallUpwards && newWall.y2 > oldWall.y2
                        ? Math.min(newWall.y2, mxY)
                        : newWall.y2
                    : oldWall.y2,
              };
              updateWall(wall, updatedWall, obj);
            }
          } else {
            let mnX: number = Number.MIN_VALUE,
              mxX: number = Number.MAX_VALUE;
            wall.roomIds.forEach((rid) => {
              let room = rooms?.find((r) => r.id === rid);
              room?.walls?.forEach((w) => {
                // if w is in continuation with wall and intersects with oldwall
                if (
                  w !== wall &&
                  w.y1 === w.y2 &&
                  w.y1 === wall.y1 &&
                  Math.abs(w.x1 - oldWall.x1) + Math.abs(w.x2 - oldWall.x1) ===
                  Math.abs(w.x1 - w.x2)
                ) {
                  if (
                    Math.abs(w.x1 - w.x2) + Math.abs(wall.x1 - wall.x2) ===
                    Math.max(w.x1, w.x2, wall.x1, wall.x2) -
                    Math.min(w.x1, w.x2, wall.x1, wall.x2)
                  ) {
                    mnX = Math.min(w.x1, w.x2);
                    mxX = Math.max(w.x1, w.x2);
                  }
                }
                // if w is in continuation with wall but doesn't intersect with oldwall
                else if (
                  w !== wall &&
                  w.y1 === w.y2 &&
                  w.y1 === wall.y1 &&
                  ((oldwallLeftwards && newWall.x1 > oldWall.x1) ||
                    (!oldwallLeftwards && newWall.x1 < oldWall.x1))
                ) {
                  if (
                    Math.abs(w.x1 - w.x2) + Math.abs(wall.x1 - wall.x2) ===
                    Math.max(w.x1, w.x2, wall.x1, wall.x2) -
                    Math.min(w.x1, w.x2, wall.x1, wall.x2)
                  ) {
                    mnX = Math.min(w.x1, w.x2);
                    mxX = Math.max(w.x1, w.x2);
                    const updatedWall: Wall = {
                      ...w,
                      x1: oldwallLeftwards
                        ? Math.max(w.x1, w.x2)
                        : Math.min(w.x1, w.x2),
                      x2: oldwallLeftwards
                        ? Math.max(mnX, newWall.x1)
                        : Math.min(mxX, newWall.x1),
                    };
                    updateWall(w, updatedWall, obj);
                  }
                }
              });
            });

            if (wall.x1 === oldWall.x1 && wall.y1 === oldWall.y1) {
              const updatedWall: Wall = {
                ...wall,
                x1:
                  !flag ||
                    (oldwallLeftwards && newWall.x1 > oldWall.x1) ||
                    (!oldwallLeftwards && newWall.x1 < oldWall.x1)
                    ? !flag && oldwallLeftwards && newWall.x1 < oldWall.x1
                      ? Math.max(newWall.x1, mnX)
                      : !flag && !oldwallLeftwards && newWall.x1 > oldWall.x1
                        ? Math.min(newWall.x1, mxX)
                        : newWall.x1
                    : oldWall.x1,
              };
              updateWall(wall, updatedWall, obj);
            } else if (wall.x2 === oldWall.x1 && wall.y2 === oldWall.y1) {
              const updatedWall: Wall = {
                ...wall,
                x2:
                  !flag ||
                    (oldwallLeftwards && newWall.x1 > oldWall.x1) ||
                    (!oldwallLeftwards && newWall.x1 < oldWall.x1)
                    ? !flag && oldwallLeftwards && newWall.x1 < oldWall.x1
                      ? Math.max(newWall.x1, mnX)
                      : !flag && !oldwallLeftwards && newWall.x1 > oldWall.x1
                        ? Math.min(newWall.x1, mxX)
                        : newWall.x1
                    : oldWall.x1,
              };
              updateWall(wall, updatedWall, obj);
            } else if (wall.x1 === oldWall.x2 && wall.y1 === oldWall.y2) {
              const updatedWall: Wall = {
                ...wall,
                x1:
                  !flag ||
                    (oldwallLeftwards && newWall.x2 > oldWall.x2) ||
                    (!oldwallLeftwards && newWall.x2 < oldWall.x2)
                    ? !flag && oldwallLeftwards && newWall.x2 < oldWall.x2
                      ? Math.max(newWall.x2, mnX)
                      : !flag && !oldwallLeftwards && newWall.x2 > oldWall.x2
                        ? Math.min(newWall.x2, mxX)
                        : newWall.x2
                    : oldWall.x2,
              };
              updateWall(wall, updatedWall, obj);
            } else if (wall.x2 === oldWall.x2 && wall.y2 === oldWall.y2) {
              const updatedWall: Wall = {
                ...wall,
                x2:
                  !flag ||
                    (oldwallLeftwards && newWall.x2 > oldWall.x2) ||
                    (!oldwallLeftwards && newWall.x2 < oldWall.x2)
                    ? !flag && oldwallLeftwards && newWall.x2 < oldWall.x2
                      ? Math.max(newWall.x2, mnX)
                      : !flag && !oldwallLeftwards && newWall.x2 > oldWall.x2
                        ? Math.min(newWall.x2, mxX)
                        : newWall.x2
                    : oldWall.x2,
              };
              updateWall(wall, updatedWall, obj);
            }
          }
        }
        // parallel
        else {
          if (
            (wall.x1 === oldWall.x1 && wall.y1 === oldWall.y1) ||
            (wall.x2 === oldWall.x1 && wall.y2 === oldWall.y1)
          ) {
            let rids: string[] = [];
            oldWall.roomIds.forEach((rid1) => {
              wall.roomIds.forEach((rid2) => {
                if (rooms) {
                  const room1: Room =
                    rooms[rooms?.findIndex((room) => room.id === rid1) || 0];
                  const room2: Room =
                    rooms[rooms?.findIndex((room) => room.id === rid2) || 0];
                  if (oldwallHorizontal) {
                    let room1Up: boolean =
                      oldWall.y1 < getRoomLimits(room1).maxY;
                    let room2Up: boolean = wall.y1 < getRoomLimits(room2).maxY;
                    if (room1Up === room2Up) {
                      if (room1Up && newWall.y1 > oldWall.y1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (room1Up) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      } else if (!room1Up && newWall.y1 < oldWall.y1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (!room1Up) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      }
                    }
                  } else {
                    let room1Left: boolean =
                      oldWall.x1 < getRoomLimits(room1).maxX;
                    let room2Left: boolean =
                      wall.x1 < getRoomLimits(room2).maxX;
                    if (room1Left === room2Left) {
                      if (room1Left && newWall.x1 > oldWall.x1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (room1Left) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      } else if (!room1Left && newWall.x1 < oldWall.x1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (!room1Left) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      }
                    }
                  }
                }
              });
            });
            const wallToAdd: Wall = {
              id: uuidv4(),
              x1: oldWall.x1,
              y1: oldWall.y1,
              x2: newWall.x1,
              y2: newWall.y1,
              roomIds: rids,
            };
            obj.updatedWalls.push(wallToAdd);
            updateRoom(wall, wallToAdd, obj, false);
          } else if (
            (wall.x1 === oldWall.x2 && wall.y1 === oldWall.y2) ||
            (wall.x2 === oldWall.x2 && wall.y2 === oldWall.y2)
          ) {
            let rids: string[] = [];
            oldWall.roomIds.forEach((rid1) => {
              wall.roomIds.forEach((rid2) => {
                if (rooms) {
                  const room1: Room =
                    rooms[rooms?.findIndex((room) => room.id === rid1) || 0];
                  const room2: Room =
                    rooms[rooms?.findIndex((room) => room.id === rid2) || 0];
                  if (oldwallHorizontal) {
                    let room1Up: boolean =
                      oldWall.y1 < getRoomLimits(room1).maxY;
                    let room2Up: boolean = wall.y1 < getRoomLimits(room2).maxY;
                    if (room1Up === room2Up) {
                      if (room1Up && newWall.y1 > oldWall.y1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (room1Up) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      } else if (!room1Up && newWall.y1 < oldWall.y1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (!room1Up) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      }
                    }
                  } else {
                    let room1Left: boolean =
                      oldWall.x1 < getRoomLimits(room1).maxX;
                    let room2Left: boolean =
                      wall.x1 < getRoomLimits(room2).maxX;
                    if (room1Left === room2Left) {
                      if (room1Left && newWall.x1 > oldWall.x1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (room1Left) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      } else if (!room1Left && newWall.x1 < oldWall.x1) {
                        rids = oldWall.roomIds.filter(
                          (rid) => rid !== room1.id,
                        );
                        rids.push(room2.id);
                      } else if (!room1Left) {
                        rids = wall.roomIds.filter((rid) => rid !== room2.id);
                        rids.push(room1.id);
                      }
                    }
                  }
                }
              });
            });
            const wallToAdd: Wall = {
              id: uuidv4(),
              x1: oldWall.x2,
              y1: oldWall.y2,
              x2: newWall.x2,
              y2: newWall.y2,
              roomIds: rids,
            };
            obj.updatedWalls.push(wallToAdd);
            updateRoom(wall, wallToAdd, obj, false);
          }
        }
      }
    });
    dispatch(setWalls(obj.updatedWalls));
    dispatch(setRooms(obj.updatedRooms));
    dispatch(setCleanUpDone(true));
  };

  const updatePlotBoundary = (oldPlotWall: Wall, newPlotWall: Wall) => {
    dispatch(clearRedoStacks());
    dispatch(
      pushUndoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }),
    );
    let updatedPlotBoundary: Wall[] = [];
    plot_boundary?.forEach((wall) => {
      let oldPlotWallHorizontal: boolean = oldPlotWall.y1 === oldPlotWall.y2;
      let wallHorizontal: boolean = wall.y1 === wall.y2;
      let arePerpendicular: boolean = oldPlotWallHorizontal !== wallHorizontal;
      if (wall === oldPlotWall) {
        updatedPlotBoundary.push(newPlotWall);
      } else if (arePerpendicular) {
        if (oldPlotWallHorizontal) {
          if (oldPlotWall.y1 === wall.y1) {
            wall = { ...wall, y1: newPlotWall.y1 };
          } else if (oldPlotWall.y1 === wall.y2) {
            wall = { ...wall, y2: newPlotWall.y1 };
          }
        } else {
          if (oldPlotWall.x1 === wall.x1) {
            wall = { ...wall, x1: newPlotWall.x1 };
          } else if (oldPlotWall.x1 === wall.x2) {
            wall = { ...wall, x2: newPlotWall.x1 };
          }
        }
        updatedPlotBoundary.push(wall);
      } else {
        updatedPlotBoundary.push(wall);
      }
    });
    dispatch(setPlot(updatedPlotBoundary));
    dispatch(setCleanUpDone(true));
  };

  const drawLine = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    drag: boolean,
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    id: string,
    isPlotBoundary: boolean,
  ) => {
    if (contentGroupRef.current)
      contentGroup = d3.select(contentGroupRef.current);
    if (drag) removeDragLine(contentGroup);

    const line = contentGroup
      .append("line")
      .attr("id", id)
      .attr("class", isPlotBoundary ? "plot" : drag ? "drag-line" : "edge")
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
      .attr("stroke", isPlotBoundary ? "gray" : "#000")
      .attr("stroke-width", 2)
      .style("cursor", "pointer")
      .call(lineDrag as any)
      .on("click", () => handleLineClick(id));

    // line;
  };

  const removeDragLine = (
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) => {
    contentGroup?.select(".drag-line").attr("class", "drag-line").remove();
  };

  const addRoomLabel = (x: number, y: number, text: string) => {
    if (contentGroupRef.current)
      contentGroup = d3.select(contentGroupRef.current);

    let roomGroup = d3.select(contentGroupRef.current).append("g");
    roomGroup.attr("class", "room-label");

    // Add text first (without positioning) to calculate its size
    let roomLabel = roomGroup
      .append("text")
      .text(formatLabel(text))
      .attr("class", "text")
      .attr("fill", "#fff")
      .attr("font-weight", "bold")
      .style("font-size", "12px"); // Adjust font size as needed

    // Get the bounding box of the text
    //@ts-ignore
    const textBBox = roomLabel.node().getBBox();

    // Define padding
    const paddingX = 10;
    const paddingY = 5;

    // Create rectangle based on text size plus padding
    let roomLabelRect = roomGroup
      .insert("rect", "text") // Insert the rect before the text
      .attr("width", textBBox.width + 2 * paddingX)
      .attr("height", textBBox.height + 2 * paddingY)
      .attr("fill", "#101010")
      .attr("rx", 5) // Horizontal radius of the corner curvature
      .attr("ry", 5)
      .attr("opacity", 0.7);

    // Position the text in the center of the rectangle
    roomLabel.attr("x", paddingX).attr("y", textBBox.height + paddingY / 2);

    // Center the entire group at the provided (x, y) coordinates
    //@ts-ignore
    const groupBBox = roomGroup.node().getBBox();
    roomGroup.attr(
      "transform",
      `translate(${x - groupBBox.width / 2}, ${y - groupBBox.height / 2})`,
    );
  };

  const addAsset = (
    width: number,
    height: number,
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    lineId: string, // Add lineId parameter
  ) => {
    let minX = 1e9;
    let maxX = -1e9;
    let minY = 1e9;
    let maxY = -1e9;

    floorPlans[index].forEach((apiRoom) => {
      // selectedFloorPlan.forEach((apiRoom) => {
      const room: Room = {
        id: apiRoom._id,
        name: apiRoom.name,
        color: getRandomColor(),
        walls: [],
      };

      apiRoom?.walls?.forEach((apiWall: any) => {
        minX = Math.min(apiWall.x1, apiWall.x2, minX);
        minY = Math.min(apiWall.y1, apiWall.y2, minY);
        maxX = Math.max(apiWall.x1, apiWall.x2, maxX);
        maxY = Math.max(apiWall.y1, apiWall.y2, maxY);
      });
    });
    const w = Math.abs(maxX - minX);
    const h = Math.abs(maxY - minY);

    if (contentGroupRef.current)
      contentGroup = d3.select(contentGroupRef.current);
    const lineSelector = `#${lineId}`;
    const line = d3.select(`[id="${lineId}"]`);
    const x1 = parseFloat(line.attr("x1"));
    const y1 = parseFloat(line.attr("y1"));
    const x2 = parseFloat(line.attr("x2"));
    const y2 = parseFloat(line.attr("y2"));
    const centerX = x1 + width / 2;
    const centerY = y1 + height / 2;
    line.attr("stroke", "#000");
    let wallLength = calculateUnscaledWallLength(x1, y1, x2, y2, w, h);
    if (wallLength < 2) {
      dispatch(
        showToast({
          message: "Wall Length must be atleast 2 feet for wall addition.",
          type: "error",
        }),
      );

      dispatch(setAssetType(null));
      return;
    }

    let rotationAngle = 0;

    let asset: any;
    const assetHeight = window.visualViewport?.height
      ? (window.visualViewport?.height * 0.5 * height) / h
      : 0;
    const assetWidth = window.visualViewport?.height
      ? (window.visualViewport?.height * 0.5 * width) / h
      : 0;

    // Determine if the line is vertical
    if (x1 === x2) {
      asset = contentGroup
        .append("image")
        .attr("x", x1)
        .attr("y", y1)
        .attr("line-id", lineId)
        .attr("width", assetWidth)
        .attr("height", assetHeight)
        .attr("href", door);
    } else {
      asset = contentGroup
        .append("image")
        .attr("x", x1)
        .attr("y", y1)
        .attr("line-id", lineId)
        .attr("width", assetWidth)
        .attr("height", assetHeight)
        .attr("href", door90);
    }
    dispatch(setAssetType(null));
    setupAssetDrag(asset, lineId, rotationAngle);
  };

  const setupAssetDrag = (asset: any, lineId: string, rotationAngle: any) => {
    // const lineSelector = `#${lineId}`;
    const line = d3.select(`[id="${lineId}"]`);
    const x1 = parseFloat(line.attr("x1"));
    const y1 = parseFloat(line.attr("y1"));
    const x2 = parseFloat(line.attr("x2"));
    const y2 = parseFloat(line.attr("y2"));

    const drag = d3
      .drag()
      .on("start", function (event) {
        d3.select(this).raise().classed("active", true);
      })
      .on("drag", function (event) {
        // Calculate new position within line constraints
        let newX = event.x;
        let newY = event.y;

        // // Keep the asset within the bounds of the line
        if (y1 === y2) {
          if (newX < Math.min(x1, x2)) newX = Math.min(x1, x2);
          if (newX > Math.max(x1, x2) - asset.attr("width"))
            newX = Math.max(x1, x2) - asset.attr("width");
          if (newY < Math.min(y1, y2)) newY = Math.min(y1, y2);
          if (newY > Math.max(y1, y2)) newY = Math.max(y1, y2);
        } else {
          if (newX < Math.min(x1, x2)) newX = Math.min(x1, x2);
          if (newX > Math.max(x1, x2)) newX = Math.max(x1, x2);
          if (newY < Math.min(y1, y2)) newY = Math.min(y1, y2);
          if (newY > Math.max(y1, y2) - asset.attr("height"))
            newY = Math.max(y1, y2) - asset.attr("height");
        }

        // Update asset position
        d3.select(this)
          .attr("x", newX) // Adjust if you want the drag to be centered
          .attr("y", newY);
      })
      .on("end", function (event) {
        d3.select(this).classed("active", false);
      });

    asset.call(drag);
  };

  function handleLineClick(lineId: string) {
    if (!draw) {
      // d3.selectAll(".edge").classed("highlighted", false);
      const wall = walls?.find((wall) => wall.id === lineId);

      if (wall) {
        //d3.select(`[id="${lineId}"]`).classed("highlighted", true);
        // addAsset(20, 20, contentGroup, lineId);
        setSelectedLineId(lineId);
        dispatch(setSelectedWall(wall));
      } else {
        // console.log("Line not found.");
        const plot_wall = plot_boundary?.find((wall) => wall.id === lineId);

        if (plot_wall) {
          //d3.select(`[id="${lineId}"]`).classed("highlighted", true);
          // addAsset(20, 20, contentGroup, lineId);
          setSelectedLineId(lineId);
          dispatch(setSelectedWall(plot_wall));
        }
      }
    }
  }

  useEffect(() => {
    // console.log("walls", walls);
  }, [walls]);
  useEffect(() => {
    if (selectedLineId && newAsset?.assetType) {
      // console.log("calling add asset from here!!", newAsset);
      addAsset(2, 2, contentGroup, selectedLineId);
    } else console.log("line not selected");
  }, [newAsset]);

  useEffect(() => {
    if (selectedWall) {
      if (selectedWallOption === "select") {
        d3.select(`[id="${selectedWall.id}"]`).classed("highlighted", true);
        dispatch(setSelectedWall(undefined));
      }
      else if (selectedWallOption === "unselect") {
        d3.select(`[id="${selectedWall.id}"]`).classed("highlighted", false);
        dispatch(setSelectedWall(undefined));
        dispatch(setSelectedWallOption(null));
      }
      else if (selectedWallOption === "delete") {
        d3.select(`[id="${selectedWall.id}"]`).classed("highlighted", false);
        deleteWall(selectedWall);
        dispatch(setSelectedWall(undefined));
        dispatch(setSelectedWallOption(null));
      }
    }
  }, [selectedWallOption])

  return { handleLineClick };
};
